import * as PusherPushNotifications from "@pusher/push-notifications-web"
import { fetchUserConfig } from "#js/components/config"

export async function initPusher() {
  const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
    url: globalThis.pusherTokenAuthUrl,
  })
  const beamsClient = new PusherPushNotifications.Client({
    instanceId: globalThis.pusherInstanceId,
  })

  const pusherUserId = await beamsClient.getUserId()
  // check if user allowed push notifications

  try {
    const config = await fetchUserConfig()
    if (Object.values(config.notifications).some((bool) => bool)) {
      // check if Beams client is already set
      if (globalThis.userId === pusherUserId) {
        console.debug("Beams: client already set, pass")
      } else {
        // set Beams client
        beamsClient
          .start()
          .then(() => beamsClient.setUserId(globalThis.userId, beamsTokenProvider))
          .then(() => console.debug("Beams: client has been set"))
          .catch((err) => console.error("Beams: could not authenticate:", err))
      }
    } else {
      // user did not allow push notifications
      if (pusherUserId != null) {
        // stop Beams client if it was set
        return beamsClient
          .stop()
          .then(() => console.debug("Beams: client has been stopped"))
          .catch((err) => console.error("Beams: could not clear state:", err))
      } else {
        console.debug("Beams: client is not set, pass")
      }
    }
  } catch (e) {
    console.error("Beams: could not fetch user config:", e)
  }
}
